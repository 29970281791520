<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('bankAccounts.title') }}</h2>
    </div>
    <div class="intro-y box grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-12 lg:col-span-12 p-5">
        <div ref="table" class="relative">
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#4469A8"
            :loading="loading"
          />
          <div class="overflow-x-auto">
            <table
              id="transactionsTable"
              ref="tabulator"
              class="table table-report sm:my-2"
            >
              <thead>
                <tr>
                  <th class="whitespace-nowrap" id="">#</th>
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    :id="
                      col.name === 'action'
                        ? 'ignore-2'
                        : col.name === 'avatar'
                        ? 'ignore-3'
                        : ''
                    "
                    class="text-center whitespace-nowrap"
                  >
                    {{ i18n(col.label) }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="accounts.length">
                <tr
                  v-for="(row, index) in accounts"
                  :key="index"
                  class="intro-x"
                >
                  <td class="w-40">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ index + 1 }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row, 'fullName') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row, 'bankName') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row, 'iban') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row, 'idNumber') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="font-medium whitespace-nowrap flex items-center justify-center"
                      :class="{
                        'text-theme-10': row.isDefault,
                        'text-theme-24': !row.isDefault
                      }"
                    >
                      {{
                        row.isDefault
                          ? i18n('bankAccounts.fields.isDefault')
                          : 'ـــ'
                      }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-empty-page
              v-if="!accounts.length && !loading"
              :label="i18n('empty.bankAccounts')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-20"
            ></app-empty-page>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GenericModel } from '@/shared/model/generic-model'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'app-wallet-accounts-table',
  props: {
    userId: {
      type: String,
      required: true
    },
    user: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      accounts: 'user/view/accounts',
      loading: 'user/view/accountsLoading'
    })
  },
  data() {
    return {
      columns: [
        {
          name: 'fullName',
          field: 'fullName',
          label: 'bankAccounts.fields.fullName',
          align: 'center'
        },
        {
          name: 'bankName',
          field: 'bankName',
          label: 'bankAccounts.fields.bankName',
          align: 'center'
        },
        {
          name: 'iban',
          field: 'iban',
          label: 'bankAccounts.fields.iban',
          align: 'center'
        },
        {
          name: 'idNumber',
          field: 'idNumber',
          label: 'bankAccounts.fields.idNumber',
          align: 'center'
        },
        {
          name: 'isDefault',
          field: 'isDefault',
          label: 'bankAccounts.fields.isDefault',
          align: 'center'
        }
      ]
    }
  },
  async mounted() {
    this.doSetUserType(this.user)
    await this.doFindAccounts(this.userId)
  },
  methods: {
    ...mapActions({
      doSetUserType: 'user/list/doSetUserType',
      doFindAccounts: 'user/view/doFindAccounts'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
