<template>
  <div id="viewer-modal-preview" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <!-- <embed
              name="plugin"
              :src="link"
              class="embed-pdf-scroll"
              :type="type"
            /> -->
            <iframe
              :src="link"
              class="embed-pdf-scroll"
              frameborder="0"
              seamless="seamless"
              scrolling="no"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
      default: ''
    },
    type: {
      type: String,
      default: 'image/*'
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-dialog {
  width: fit-content !important;
}
.embed-pdf-scroll {
  height: 80vh;
  width: 80vw;
}
</style>
